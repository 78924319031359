<!--
 * @Descripttion: 专家抽取 - 第二步：项目抽取
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 10:24:39
-->
<template>
    <div class="pad">
        <div class="pad flex-col whiteBg" v-if="isDo">
            <van-circle v-model="currentRate"
                speed="50"
                size="5rem"
                :rate="rate"
                stroke-width="60"
                :text="circleText"
                :color="resultColor"
                layer-color="#E1FFFB"
                class="extractionText"
                :class="{failText : isFail}"
            />
            <p class="pad">{{ textInfo }}</p>
            <p class="h75" v-show="!isFail"></p>
            <button class="fail whiteBg h75" v-show="isFail" @click="reLoad">重试</button>
        </div>
    </div>
</template>

<script>
import { Circle } from 'vant'
import expertList from  '@/test/expertList'
export default {

    data(){
        return{
            rate : 100,
            currentRate : 0,
            circleText : '正在抽取',
            textInfo : '智能抽取中，请耐心等待...',
            isFail : false,
            resultColor : '#00CCB2',
            selectedMajors : [],
            isDo : true
        }
    },

    components:{
        [Circle.name]:Circle
    },

    watch:{
        currentRate(tt){
            if(tt == this.rate) {
                this.circleText = '抽取成功'
                this.textInfo = '已抽取成功，请稍等…'
                this.isFail = false
                this.resultColor = '#00CCB2',
                setTimeout(() => {
                    this.$ls.set('major-demo-list', this.selectedMajors)
                    this.$router.push({
                        name: 'demo-3',
                        params: {
                            type: 'demo'
                        }
                    })
                }, 1000);
            }
        }
    },
    created(){
        if(typeof this.$route.params.type =='undefined' || this.$route.params.type !='extract'){
            this.$router.push('/demo-1')
            this.isDo = false
            return
        }
    },
    beforeMount(){
        if(this.isDo){
            this.$emit('onActive',2)
            this.getMajor()
        }
    },

    methods:{
        reLoad(){
            window.location.reload()
        },
        /** 获取项目专业信息 */
        getMajor(){
            this.selectedMajors = this.$ls.get('major-demo-list') || []
            if(this.selectedMajors.length == 0) this.$router.push('/demo')
            else{
                /** 开始抽取并创建轮次，将抽取结果和轮次信息放入缓存 */
                let rounds = [{
                    roundNum: 1,
                    experts : []
                }]
                // for (let i = 0; i < this.selectedMajors.length; i++) {
                //     const tt = this.selectedMajors[i];
                //     console.log(tt);
                //     tt.tempNum = tt.num || 0
                //     tt.old = true
                //     for (let j = 0; j < tt.tempNum; j++) {
                //         let expert = this.getExpert()
                //         if(typeof expert =="undefined"){
                //             expert = this.getExpert()
                //         }
                //         console.log("--------------------");
                //         console.log(expert);
                //         rounds[0].experts.push(expert)
                //     }
                // }
                // /** 如果 指定抽取的专家数量 少于 项目制定的专家数 则再次抽取 */
                // let temp = this.$ls.get('demo-project').expertNum - rounds[0].experts.length
                // for (let j = 0; j < temp; j++) {
                //     console.log("抽取数量不足，再次抽取");
                //     let expert = this.getExpert()
                //
                //     rounds[0].experts.push(expert)
                // }
                let max = parseInt(this.$ls.get('demo-project').expertNum)
                for (let j = 0; j < max; j++) {
                    let expert = this.getExpert()
                    if(typeof expert =="undefined" || expert == null){
                        console.log('专家为空');
                        expert = {
                            id: 'test' + j,
                            name: '专家' + (j+1),
                            confirm: '0',
                            mobile: '188666' + '' +  Math.floor(Math.random() * 100000)
                        }
                    }
                    rounds[0].experts.push(expert)
                }
                /** 设置抽取条件不可更改 */
                this.selectedMajors.forEach( item =>{
                    item.old = true
                })

                this.$ls.set('rounds-demo', rounds, 2 * 60 * 60 * 1000)
            }
        },

        getExpert(){
            let randomNum = Math.floor( Math.random() * 90);

            if(expertList.list[randomNum] == null){
                this.getExpert()
            }else if(!expertList.list[randomNum].ban || expertList.list[randomNum].ban =='0'){
                expertList.list[randomNum].ban = '1'
                expertList.list[randomNum].confirm = '0'
                return expertList.list[randomNum]
            }else {
                this.getExpert()
            }
        },
    }
}
</script>

<style scoped>
    .h75{height: 0.75rem;}
</style>